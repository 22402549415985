<!--基本信息-->
<template>
  <div class="app-page-1">
    <div class="mineBox">
      <div class="top" >
        <div class="headimg" v-if="getUserInfo">
          <img :src="getUserInfo.headImg">
        </div>
        <div class="headimg" v-else>
          <img src="@/assets/images/mine/headImg.png">
        </div>
        <div class="name" v-if="getUserInfo" @click="navClick(5)">
          <p>{{getUserInfo.name}}</p>
          <span>选博学 赢未来</span>
        </div>
        <div class="name" v-else>
          <p class="nologin" @click="login(0)">请登录</p>
        </div>
        <div class="arrow-r">
          <img src="@/assets/images/icon/arrow-right.png">
        </div>
      </div>
      <div class="info-list">
        <ul>
          <li class="item1"  v-if="!getUserInfo" @click="login(0)">
            <div class="info-icon">
              <img src="@/assets/images/icon/icon-8.png">
            </div>
            <p>我的课程</p>
            <div class="arrow-r">
              <img src="@/assets/images/icon/arrow-right.png">
            </div>
          </li>
          <li class="item1" v-else  @click="navClick(0)">
            <div class="info-icon">
              <img src="@/assets/images/icon/icon-8.png">
            </div>
            <p>我的课程</p>
            <div class="arrow-r">
              <img src="@/assets/images/icon/arrow-right.png">
            </div>
          </li>

          <li class="item2"  v-if="!getUserInfo" @click="login(0)">
            <div class="info-icon">
              <img src="@/assets/images/icon/icon-9.png">
            </div>
            <p>我的订单</p>
            <div class="arrow-r">
              <img src="@/assets/images/icon/arrow-right.png">
            </div>
          </li>
          <li class="item2" v-else  @click="navClick(1)">
            <div class="info-icon">
              <img src="@/assets/images/icon/icon-9.png">
            </div>
            <p>我的订单</p>
            <div class="arrow-r">
              <img src="@/assets/images/icon/arrow-right.png">
            </div>
          </li>

          <li class="item3"  v-if="!getUserInfo" @click="login(0)">
            <div class="info-icon">
              <img src="@/assets/images/icon/icon-10.png">
            </div>
            <p>课程协议</p>
            <div class="arrow-r">
              <img src="@/assets/images/icon/arrow-right.png">
            </div>
          </li>
          <li class="item3" v-else @click="navClick(2)">
            <div class="info-icon">
              <img src="@/assets/images/icon/icon-10.png">
            </div>
            <p>课程协议</p>
            <div class="arrow-r">
              <img src="@/assets/images/icon/arrow-right.png">
            </div>
          </li>

          <li class="item4" v-if="!getUserInfo" @click="login(0)">
            <div class="info-icon">
              <img src="@/assets/images/icon/icon-11.png">
            </div>
            <p>修改密码</p>
            <div class="arrow-r">
              <img src="@/assets/images/icon/arrow-right.png">
            </div>
          </li>
          <li class="item4" v-else @click="navClick(3)">
            <div class="info-icon">
              <img src="@/assets/images/icon/icon-11.png">
            </div>
            <p>修改密码</p>
            <div class="arrow-r">
              <img src="@/assets/images/icon/arrow-right.png">
            </div>
          </li>

          <li class="item5" v-if="!getUserInfo" @click="login(0)">
            <div class="info-icon">
              <img src="@/assets/images/icon/icon-12.png">
            </div>
            <p>疑问与帮助</p>
            <div class="arrow-r">
              <img src="@/assets/images/icon/arrow-right.png">
            </div>
          </li>
          <li class="item5" v-else @click="navClick(4)">
            <div class="info-icon">
              <img src="@/assets/images/icon/icon-12.png">
            </div>
            <p>疑问与帮助</p>
            <div class="arrow-r">
              <img src="@/assets/images/icon/arrow-right.png">
            </div>
          </li>

        </ul>
        <!-- <div  v-if="getUserInfo" class="logout-btn" @click="logout()">
          <span>退出登录</span>
        </div> -->
      </div>
    </div>

  </div>
</template>
<script>
// import { logout } from '@/api/api'
export default {
    name:'info',
    data(){
      return {
        isEdit:false,
        infoForm:{},
        imageUrl:null
      }
    },
    components: {
      
    },
    computed: {
      getUserInfo() {
        if(this.$store.state) {
            this.infoForm = JSON.parse( JSON.stringify(this.$store.state.userInfo))
            return this.$store.state.userInfo || null
            
        } else {
            return null
        }
      }
    },
    methods: {
      navClick(val = 0) {
        this.$router.push({path: `/mine/mineInfo?type=${val}`})
        // this.$emit('onChange',val)
      },

      // async logout() {
      //   let response = await this.$axios.post(logout)
      //   if(+response.returnCode !== 10001) {
      //     this.$toast(response.returnMsg)
      //     // return false
      //   }
      //   this.$store.commit('user/logout')
      //   this.$toast('退出成功')
      //   this.$nextTick(() => {
      //     window.location.reload()
      //   })
      // },

      login(val = 0) {
        this.$store.dispatch('toggleDialog', true)
      },
    },    
}
</script>
<style lang="less" scoped>
.mineBox{
  padding:0 5%;
  background:#fff;
}
.top{
  width: 100%;
  height:1rem;
  padding:0.5rem 0;
  display: flex;
  align-items: center;
}
.top .headimg{
  width:1rem;
  height:1rem;
}
.top img{
  width:1rem;
  height:1rem;
  border-radius:50%;
}
.top .name{
  margin-left:0.26rem;
  text-align: left;
  display: flex;
  flex-direction:column;
  flex-grow:2;
}
.top .name p{
  font-size: 18px;
  line-height: 0.6rem;
  color:#000;
}
.top .name .nologin{
  font-size: 16px;
  line-height: 1rem;
  padding-left: 0.2rem;
}
.top .name span{
  font-size: 14px;
  color:#B0B0B0;
}
.arrow-r {
  height:1rem;
  display: inline-flex;
  align-items: center;
}
.arrow-r img{
  width:0.13rem;
  height:0.23rem;
  border-radius: 0;
}
.info-list ul li{
  display: flex;
  align-items: center;
  padding:0.22rem 0;
  font-size: 16px; 
  color:#000;
  height:0.5rem;
  line-height: 0.5rem;
}
.info-list ul li p{
  margin-left: 0.1rem;
  flex-grow:2;
  text-align: left;
}
.info-list ul .info-icon{
  width:0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
}
.info-list ul .item1 .info-icon img{
  width:0.4rem;
  height:0.27rem;
}
.info-list ul .item2 .info-icon img{
  width:0.33rem;
  height:0.38rem;
}
.info-list ul .item3 .info-icon img{
  width:0.32rem;
  height:0.35rem;
}
.info-list ul .item4 .info-icon img{
  width:0.36rem;
  height:0.35rem;
}
.info-list ul .item5 .info-icon img{
  width:0.34rem;
  height:0.34rem;
}
.logout-btn span{
  display: block;
  width:94%;
  height:0.8rem;
  line-height: 0.8rem;
  text-align: center;
  background:#FF3F47;
  color:#fff;
  font-size: 14px;
  margin:1rem auto;
  border-radius: 10px;
}
</style>
